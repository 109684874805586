import { Callout, Code, Button, Divider, Intent } from "@blueprintjs/core";
import React from 'react';
import SWEngImg from '../../images/sw-eng-image.svg';

const Welcome = () => {
    return <div
        className="bp5-dark"
        style={{
            padding: 5,
            height: '90vh',
        }}>
        <div style={{ overflowY: 'scroll', height: '80vh' }}>
            <div style={{ margin: 50 }}>
                <h1 className="bp5-heading">BodaPro</h1>
                <Divider />
                <h3 className="bp5-text-muted" style={{ marginTop: 10 }}>Productivity Redefined</h3>
                <div style={{
                    marginTop: 50,
                }}>

                    <div style={{ display: 'flex', flexDirection: 'row' }} key="quick-actions">
                        <div style={{ flex: 1 }}>
                            <h3>Quick actions</h3>

                            <ul className="bp5-list">
                                <li>Browse documentation</li>
                                <li>Browse extensions</li>
                                <li>Get support</li>

                            </ul>
                        </div>
                        <div style={{ flex: 1 }}>
                            <img src={SWEngImg} alt="Logo" width={128} height={128} />
                        </div>

                    </div>

                    <br />

                    <div key="bodapro-intro">
                        <Callout title="BodaPro">
                            {[
                                <React.Fragment key="intro-text">
                                    This is the first release of BodaPro. It is a lightweight productivity tool.
                                </React.Fragment>,
                                <ul key="features">
                                    <li key="1">A growing repository  of extensions</li>
                                    <li key="2">Available on Windows, Mac, and Linux </li>
                                </ul>,
                                <br key="line-br"/>,
                                <Button text="Read more" intent="primary" key="read-more" />,
                                undefined,
                            ]}
                        </Callout>
                    </div>

                    <br />

                    <div key="bodapro-server-callout">
                        <Callout title="BodaPro Server">
                            {[
                                <React.Fragment>
                                    Scale your productivity with the server version.
                                </React.Fragment>,
                                <hr />,
                                <Button text="Visit site" intent="primary" />,
                                undefined,
                            ]}
                        </Callout>
                    </div>

                    <br />

                    <div key="bodapro-online-callout">
                        <Callout title="BodaPro Online">
                            {[
                                <React.Fragment>
                                    Forget the infrastructure management and focus on your productivity.
                                </React.Fragment>,
                                <hr />,
                                <Button text="Visit site" intent="primary" />,
                                undefined,
                            ]}
                        </Callout>
                    </div>

                </div>

            </div>


        </div>

    </div>;
}

export default Welcome