import { combineReducers } from 'redux';
import session from './modules/session/session-reducers';
import help from './modules/help/help-reducers';
import uiLayout from './modules/layout/uilayout-reducers';
import cm  from './modules/cm/cm-reducers';
import profile from './modules/profile/profile-reducers';
import settings from './modules/settings/settings-reducer';
import reports from './modules/reports/reports-reducer';
import gis from './modules/gis/gis-reducer';
import kml from './modules/utilities/kml-reducer';
import dashboard from './modules/dashboard/dashboard-reducer';
import extensions from './modules/extensions/extensions-reducer';
// const appReducer = combineReducers({
//   session,
//   help,
//   uiLayout,
//   cm,//@todo: remove
//   profile,//@todo: remove
//   settings,
//   reports,//@todo: remove
//   gis,//@todo: remove
//   kml,//@todo: remove
//   dashboard //@todo: remove
// });

const coreReducers = {
  session,
  help,
  uiLayout,
  // cm,//@todo: remove
  // profile,//@todo: remove
  settings,
  extensions,
  // reports,//@todo: remove
  // gis,//@todo: remove
  // kml,//@todo: remove
  // dashboard //@todo: remove
};

export const createReducer = (asyncReducers) => {
  return combineReducers({
    ...coreReducers,
    ...asyncReducers
  });
};


// export default appReducer;
export default createReducer;