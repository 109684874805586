import React from 'react';
import { Button, Divider, mergeRefs, Popover, Tooltip, Icon, IconSize } from "@blueprintjs/core";
import ActivityBarIcon from "../../components/ActivityBarIcon";
// import Logo from '../../images/logo.png';
import Logo from '../../images/logo.svg';
import  './activity-bar.css';

const ActivtyBar = (props) => {
   const { contributions, } = props.params;
   const [showSearch, setShowSearch] = React.useState(false);
   const [search, setSearch] = React.useState('');


    return (<div className="bp5-dark" style={{
        flexDirection: 'column', 
        alignSelf:'stretch', 
        height: '95vh',
        display: 'flex'}}>

        <div style={{flex:1, overflowY: 'scroll'}} className="activity-extensions">
            <div style={{margin: 15, marginBottom: 20}}>
                <Tooltip content="Home">
                    {/* <Icon icon="home" size={24} intent="primary" /> */}
                    <img src={Logo} alt="Logo" width={30} height={30} />
                </Tooltip>
            </div>
            <div style={{margin: 15, marginBottom: 20}} key="activityIcons">
                <Popover
                    content={<input type="search" 
                        placeholder="Search..." 
                        style={{width: 200, height: 30}}  
                        onChange={(e) => { 
                            setSearch(e.target.value);
                            if(globalThis.commands$['bss.aws:handle-command']) { 
                                globalThis.commands$['bss.aws:handle-command'].next(e.target.value);
                            }
                        }} value={search}/>}
                    // position="bottom"
                    placement="right"
                    isOpen={showSearch}
                    onClose={() => {
                        setShowSearch(false);
                    }}
                >
                <Tooltip content="Activity Bar Search">
                    <Icon icon={search ? "search-template" : "search"} size={24} intent="secondary" 
                        color={search ? "#2d72d2" : null}
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}
                    />
                </Tooltip>
                </Popover>
            </div>
            <div style={{margin: 15, marginBottom: 20}} key="extensions">
                <Tooltip content="Extensions">
                    <Icon icon="cube" size={24} intent="secondary" />
                </Tooltip>
            </div>
            {contributions.filter(v => {
            const p = new RegExp(search, 'gi');
            if (search === undefined) return true;
            if (search.length === 0) return true;
            if (p.test(v.name)) return true;
            return false;
            }).map((contribution, idx) => {
                return (<div style={{margin: 15, marginBottom: 20}} key={idx}>
                    <Tooltip content={contribution.label}>
                        <ActivityBarIcon icon={contribution.icon} size={24} onClick={() => { 
                            if(globalThis.commands$[contribution.id]) globalThis.commands$[contribution.id].next(contribution.name);
                        }}/>
                    </Tooltip>
                </div>);
            })}
        </div>
        
        <div>
            <Divider />
            
            <div style={{margin: 15}}>
                <Tooltip content="Account"> 
                    <Icon icon="user" size={24} intent="secondary" />
                </Tooltip>    
            </div>
            
            <div style={{margin: 15}}>
                <Tooltip content="Settings"> 
                    <Icon icon="cog" size={24} intent="secondary" />
                </Tooltip>
            </div>
            
        </div>
    </div>);
}

export default ActivtyBar