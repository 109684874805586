
const StatusBar = () => {
    return (<div className="bp5-dark" style={{display: 'flex', padding: 5}}>
        <div style={{}}>
            Online
        </div>
        <div style={{flex: 1}}></div>
        <div>v1.0.0</div>
    </div>);
}

export default StatusBar;