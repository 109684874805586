import React, { useEffect } from 'react';
import LoginForm from './modules/session/LoginForm';
import ConfiguringApp from './modules/session/ConfiguringApp';
import UILayout from './modules/layoutv2/UILayout';
import { useSelector, useDispatch } from 'react-redux';
import ErrorBoundary from './modules/layout/ErrorBoundary';
import VERSION from './version';
import { resetState } from './modules/session/session-actions'
import { useParams } from 'react-router-dom';

if(!window.bodapro){
	window.bodapro = {
		// platformType: process.versions['electron'] ? 'desktop': 'web',
		platformType: window && window.process && window.process.type ? 'desktop': 'web',
		dbQuery : () => false,
		migrateUp: () => {},
		submitCode: () => {},
		addToLog: () => {},
		getInstalledExtensions: () => {},
		api : {},
		fs: { existsSync: (v) => true },
		runWorker: () => {}, //@todo: implement web socket for server implementation
	};

}

const App = () => {
	const [loading, setLoading] = React.useState(true);
	const { authenticated, version, updating } = useSelector((state) => state.session);
	const [ error,setError ] = React.useState(null);
	const [ errorInfo,setErrorInfo ] = React.useState(null);
	const dispatch = useDispatch();
	let { extension } = useParams();

	console.log('extension:', extension); 

	useEffect(() => {
		VERSION !== version && dispatch(resetState());
	});

	//show updating skin
	// if(updating) {
	// 	return <ConfiguringApp />;
	// }

	// authentication
	if(window.bodapro?.platformType == 'web'){//show authentication for web


		//@todo: load single extension
		if(extension !== undefined ){

			return <div>Loading ...</div>
		}

		if (authenticated === false) {
			return (
				<ErrorBoundary>
					<LoginForm/>
				</ErrorBoundary>
			);
		}
	}


	return (
		<ErrorBoundary>
			<UILayout className="bp5-dark"/>
		</ErrorBoundary>
	);
}

export default App;