import { Card, CardList, Icon, Text } from "@blueprintjs/core";

const HelpSideBar = () => {

    const HELP_LIST = [
        {
            id: 1,
            title: "Getting Started",
            content: "Getting started with Boda platform"
        },
        {
            id: 2,
            title: "How to navigate",
            content: "How to navigate Boda platform"
        },
        {
            id: 3,
            title: "Introduction to Extensions",
            content: "Introduction to Boda extensions"
        }
    ];

    return (
        <div className="bp5-dark p-2">
            <ul className="bp5-list bp5-list-unstyled">
                {HELP_LIST.map((v, i) => (<li className="mb-2" key={i}><Text ellipsize><Icon icon="help" /> <a href="#core/typography.running-text">{v.title}a</a></Text></li>))}
                
            </ul>
        </div>
    );
}

export default HelpSideBar;