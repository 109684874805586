// export const API_BASE_URL = typeof process !== undefined ? 'http://localhost:8181' : 'https://api.bodapro.app' //192.168.1.3:8001
// export const API_BASE_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.bodapro?.env !== 'development'  || window?.location?.port !== '3000' ? 'https://api.bodapro.app' : 'http://192.168.100.8:8181';  //192.168.1.3:8001
export const API_BASE_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.location?.port !== '3000' ? 'https://api.bodapro.app' : 'http://192.168.100.8:8181';  //192.168.1.3:8001
export const BASE_URL = 'https://api.bodapro.app';
// export const EXTENSION_ASSETS_URL = typeof process !== undefined ? 'http://localhost:8282' : 'https://extends.bodapro.app' //
// export const EXTENSION_ASSETS_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.bodapro?.env !== 'development' || window?.location?.port !== '3000'  ? 'https://extends.bodapro.app' : 'http://192.168.100.8:8282';  //
export const EXTENSION_ASSETS_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.location?.port !== '3000'  ? 'https://extends.bodapro.app' : 'http://192.168.100.8:8282';  //

// export const DOWNLOAD_ASSETS_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.bodapro?.env !== 'development'  || window?.location?.port !== '3000'  ? 'https://downloads.bodapro.app' : 'http://192.168.100.8:8383';  //
export const DOWNLOAD_ASSETS_URL = (typeof process === 'undefined' && window.location.hostname === 'online.bodapro.app') || window?.location?.port !== '3000'  ? 'https://downloads.bodapro.app' : 'http://192.168.100.8:8383';  //

const axios = require('axios'); 

export function getAxios(token, options){
	let tokenHeader = {};
	if(token !== null && token !== undefined){
		tokenHeader = {'Authorization': 'Bearer ' + token};
	}

    console.log('options?.assetsURL:', options?.assetsURL);

    const instance = axios.create({
        baseURL:  options?.assetsURL ? options?.assetsURL :  API_BASE_URL,
        timeout: 5000, //5seconds
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			...tokenHeader
		},
        transformRequest: [function (data, headers) {
            return data;
        }],
    });

    return instance;

}


export const makeAuthRequest = async (email, password) => {
    try{
        const instance = getAxios();
        if(bodapro.platformType === 'desktop'){
            const row =  await bodapro.dbQuery(`SELECT * FROM users WHERE email = "${email}" AND password = "${password}"`);
            return row ? row[0] : null;
        }
        console.log({email, password});
        const res = await instance.post('/accounts/login', JSON.stringify({email, password}));
        return res.data;
    }catch(e){
        console.log(e);
        return null;
    }

}

export const fetchUserExtensions = async (token) => {
    try{
        console.log('typeof process:', typeof process);
        if(typeof process !== 'undefined'){
            return [];
        }

        const instance = getAxios(token);
        const res = await instance.get('/extensions/user-extensions/');
        console.log('res', res);
        return res.data;
    }catch(e){
        console.log(e);
        return null;
    }
}

export const getExtensions = async (filter) => {
    const q = filter ? filter: '';
    try{
        const instance = getAxios();
        //@todo: check if running in dev or prod
        const resp = await instance.get('/extensions/extensions/?label=' + q);
        // console.log(resp.data);
        return resp.data
        // console.log('Extensions:', resp.data);
    }catch(e){
        return []
    }
}

/**
 * Get extension package.json
 * 
 * @param {*} name 
 * @returns 
 */
export const getExtensionConfig = async (name) => {
    try{
        const instance = getAxios(null, { assetsURL: EXTENSION_ASSETS_URL});
        const resp = await instance.get(`/${name}/package.json`);
        return resp.data
    }catch(e){
        return null;
    }
}