import React from 'react';
import * as Icons from '@blueprintjs/icons';
import { ReactSVG } from "react-svg";
import { Icon } from '@blueprintjs/core';

const ActivityBarIcon2 = (props) => {
        const { icon } = props;

        const camelize = (str) => {
            let _str = str.replace(/-(.)/g, (m, chr) => chr.toUpperCase().replace(/-/g, ''));
            return _str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
              return word.toUpperCase();
            }).replace(/\s+/g, '');
        }
        
        //check svg 
        if(icon.endsWith('.svg')){
            console.log('icon:', icon);
            return (<ReactSVG 
                        onClick={() => typeof props.onClick === "function" && props.onClick()}
                        loading={() => <span>Loading</span>}
                        src={icon} 
                        afterInjection={(svg) => {
                            // console.log(svg)
                          }}
                        beforeInjection={(svg) => {
                            const size = props.size || 24;
                            svg.classList.add('bp5-dark');
                            svg.setAttribute('style', `width: ${size}px; height: ${size}px; fill: currentColor;`);
                        }}
                    />);
        }

        const camelizedIcon = camelize(icon);
        if(Icons[camelizedIcon] !== undefined){
            return ( <Icon icon={icon} size={24} intent="secondary" onClick={() => props.onClick()}/>);
        }

        return (<Icon icon="help" size={24} intent="secondary" onClick={() => props.onClick()}/>);
    

    }

export default ActivityBarIcon2;