export const EXTENSION_INSTALL = 'EXTENSION_INSTALL';
export const EXTENSION_INSTALL_CLEAR = 'EXTENSION_INSTALL_CLEAR';
export const REGISTER_EXTENSION = 'REGISTER_EXTENSION';
export const UNREGISTER_EXTENSION = 'UNREGISTER_EXTENSION';

export function setInstallingExtension(extension){
	return {
		type: EXTENSION_INSTALL,
		extension
	};
}

export function clearInstallingExtension(){
	return {
		type: EXTENSION_INSTALL_CLEAR
	};
}

export function setInstalledExtensions(extensions){
	return {
		type: 'SET_INSTALLED_EXTENSIONS',
		extensions
	};
}

export function registerExtension(extension){
	return {
		type: 'REGISTER_EXTENSION',
		extension
	};
}

export function unregisterExtension(extension){
	return {
		type: 'UNREGISTER_EXTENSION',
		extension
	};
}