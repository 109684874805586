import React from 'react';
import { Button, Icon,  InputGroup, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import ResultItem from './ResultItem';
import { getExtensions } from '../../services/api';
import { useSelector } from 'react-redux';
const ExtensionSidebar = () => {

    const [search, setSearch] = React.useState("");
    const [requesting, setRequesting] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState([]);
    const { installedExtensions } = useSelector(state => state.extensions);


    const getAllExtensions = async (filter) => {
        //@todo: check if we are in production or development
        //@todo: add pagination
        console.log('getAllExtensions');
        setRequesting(true);
        try{
            const data = await getExtensions(filter);
            console.log('getAllExtensions - 2', data);
            if(data?.results) setSearchResults(data.results);
        }catch(e){
            console.log(e);
        }

        setRequesting(false);
    }

    React.useEffect( () => {
        getAllExtensions();
    }, []);


    return (<div className="bp5-dark p-2" style={{ height: '100%' }}>
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 1, marginRight: 6}}>
                <InputGroup
                    style={{
                        width: '100%'
                    }}
                    type="search" 
                    className="bp3-input" 
                    placeholder="Search extensions" 
                    dir="auto"
                    onChange={(event) => { 
                        // setSearch(event.target.value);
                        getAllExtensions(event.target.value);
                    }} />
                </div>

                <Icon icon="upload" size={24}   intent={Intent.PRIMARY} style={{marginTop: 2}}/>
            </div>
           

            <hr />
        </div>
        <div style={{ height: '90%' }}>
            <div style={{ overflowY: 'scroll', height: '100%' }}>
                <div style={{ marginRight: 10 }}>
                    {requesting && <Spinner size={SpinnerSize.LARGE}/>}
                    <ResultItem results={searchResults} search={search} installedExtensions={installedExtensions}/>
                </div>
            </div>
        </div>
    </div>);
}

export default ExtensionSidebar;