import React from 'react';
import * as Blueprint from "@blueprintjs/core";
import * as ReactRedux from "react-redux";
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './configure-store';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Loading from './modules/session/Loading';
import * as DataTable from 'ag-grid-react';
import * as serviceWorker from './serviceWorker';
import * as Dockview from 'dockview';
import * as SplitPaneReact from 'react-split-pane';
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,
  } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';


//Blueprintjs
import 'normalize.css/normalize.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'reflect-metadata';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faAt, faSpinner, faHome, faPlug, faCog, faDownload,
	faQuestionCircle, faUser, faSitemap, faWrench, faPuzzlePiece,faSync,
	faStopCircle, faUniversity, faCogs, faPowerOff, faArrowRight, faList,
	faChartArea, faBrain, faGem, faUserMd, faGlobeAfrica, faPeopleCarry,
	faFolder, faFile, faStar, faChevronRight, faDotCircle, faFolderOpen,
	faLink, faClock, faRss, faChartLine, faSquare, faTable, faInfoCircle
	,faAsterisk, faFileAlt,faFrown,faDatabase, faFileExcel, faFileCsv,
	faBroadcastTower, faPencilRuler, faBook,faCloudUploadAlt,faTools,
	faCandyCane,faHatWizard, faGlobe, faEllipsisH, faCode, faBolt, faSlidersH,
	faPlay, faPlayCircle, faPause, faStop, faStepForward, faStepBackward, faFastForward,
	faProjectDiagram
} from '@fortawesome/free-solid-svg-icons'

import { faTwitter, faFacebook, faInstagram, faYoutube, faGithub, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faLock, faAt, faSpinner, faHome, faPlug, faCog, faDownload,
faQuestionCircle, faUser, faSitemap, faWrench, faPuzzlePiece,faSync,
faStopCircle, faUniversity, faCogs, faPowerOff, faArrowRight, faList,
faChartArea, faBrain, faGem, faUserMd, faGlobeAfrica, faPeopleCarry,
faFolder, faFile, faStar, faChevronRight, faDotCircle, faFolderOpen, 
faLink, faClock, faRss, faChartLine, faSquare, faTable, faInfoCircle,
faAsterisk, faFileAlt,faFrown, faDatabase, faFileExcel, faFileCsv,
faBroadcastTower, faPencilRuler, faBook, faCloudUploadAlt, faTools, 
faCandyCane, faHatWizard, faGlobe, faEllipsisH, faCode, faBolt, faSlidersH,
faGithub, faTwitter, faFacebook, faInstagram, faYoutube, faGoogle, faLinkedin);

//Add to window for use with extensions
//-------------------------------------
window.react = React;
window["@blueprintjs/core"] = Blueprint;
window["react-redux"] = ReactRedux;
window['redux-store'] = store;
window['ag-grid-react'] = DataTable;
window['dockview'] = Dockview;
window['react-split-pane'] = SplitPaneReact;
//-------------------------------------

//exntesion
window['BODAPRO_EXTENSIONS'] = [];
//will hold the rxjs events
window.commands$ = {};
const root = createRoot(document.getElementById('root'));
// root.render(
// 	<Provider store={store} key="provider">
// 		<PersistGate loading={<Loading show={true}/>} persistor={persistor}>
// 			<App persistor={persistor}/>
// 		</PersistGate>
// 	</Provider>
// );


const router = createBrowserRouter([
	{
	  path: "/extensions/:extension",
	  element: (
		<Provider store={store} key="provider">
		  <PersistGate loading={<Loading show={true}/>} persistor={persistor}>
			  <App persistor={persistor}/>
		  </PersistGate>
		</Provider>),
	},
	{
	  path: "*",
	  element: (
	  	<Provider store={store} key="provider">
			<PersistGate loading={<Loading show={true}/>} persistor={persistor}>
				<App persistor={persistor}/>
			</PersistGate>
  		</Provider>),
	},
  ]);
root.render(<RouterProvider router={router} />);

	

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
