import {
	EXTENSION_INSTALL,
	EXTENSION_INSTALL_CLEAR,
	REGISTER_EXTENSION,
	UNREGISTER_EXTENSION
} from './extensions-actions';

const INITIAL_STATE = {
	installingExtension: false,
	installingExtensionName: null,
	installedExtensions: []
}

export default function extensions(state = INITIAL_STATE, action){
	switch (action.type) {
		case EXTENSION_INSTALL:
			return {
				...state,
				installingExtensionName: action.extension,
				installingExtension: true
			};
		case EXTENSION_INSTALL_CLEAR:
			return {
				...state,
				installingExtensionName: null,
				installing: false
			};
		case 'SET_INSTALLED_EXTENSIONS':
			return {
				...state,
				installedExtensions: action.extensions
			};
		case REGISTER_EXTENSION:
			return {
				...state,
				installedExtensions: [...state.installedExtensions, action.extension]
			};
		case UNREGISTER_EXTENSION:
			return {
				...state,
				installedExtensions: state.installedExtensions.filter(v => v !== action.extension)
			};
		default:
			return state;
	}
}